import React, { useContext, useEffect, useState } from "react";
import { Grid, makeStyles, Button, Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import SnackBar from "../../../../components/SnackBar";
import DeleteIcon from "../../../../components/SVG/Delete";
import CopyIcon from "../../../../components/SVG/Copy";
import MenuBookIcon from "../../../../components/SVG/Menu";
import CircleClose from "../../../../components/SVG/CircleClose";
import MyContext from "../../../../Provider/MyContext";
import { ConvertPrice, parseToFloat } from "../../../../utils/util";
import RightDrawer from "../../../../components/RightDrawer";
import AlertDialogDelete from "../../../../components/AlertDialogDelete";
import AlertDialog from "../../../../components/Dialog";
import {
  fetchItemInfoById,
  createItemsByCatId,
  editItemsByCatId,
  deleteItemsByCatId,
  copyItemById,
  fetchAllCategories,
  fetchAllAddons,
} from "../../../../redux/actions";
import { AvenirBlackH4, H4 } from "../../../../utils/text";
import SingleSelect from "../../../../components/SingleSelect";
import Basic from "./Basic";

const useStyles = makeStyles({
  inputText: {
    fontSize: 14,
    color: "#051D33",
    fontFamily: "AvenirLT",
  },
  h: {
    height: "100%",
  },
});
const CreateItem = ({
  open,
  id,
  callback,
  onClose,
  itemId,
  deleteItemCallback,
  copyItemCallback,
  isAlcohol,
}) => {
  const Context = useContext(MyContext);

  const dispatch = useDispatch();
  const [snackbar, setSnackbar] = useState(null);
  const [basicInfo, setbasicInfo] = useState({
    name: "",
    price: "",
    desc: "",
    image: null,
    isActive: true,
    isAlcohol: false,
    addedModifiers: [],
    stock: true,
    bg_colour: "#FFFFFF",
    font_colour: "#000000",
    button_style: 1,
  });
  const [catId, setCatId] = useState(id || "");
  const [advInfo, setAdvInfo] = useState({
    servings: [
      {
        name: "Base",
        pricings: [],
      },
    ],
    modifiers: [],
    tax_mode: "category-tax",
    taxp_id: null,
    tax_inclusive: false,
  });
  const [selectedOrderTypes, setSelectedOrderTypes] = useState([]);
  const ordertypes = useSelector((state) => state.metaReducer.ordertypes);
  const addonsList = useSelector((state) => state.menu.addons);
  const classes = useStyles();
  const [showDeleteDialog, toggleDeleteDialog] = useState(false);
  const [showCopyDialog, toggleCopyDialog] = useState(false);
  const [isEdit, toggleIsEdit] = useState(true);
  const [itemNameValidationErr, setItemNameValidationErr] = useState("");
  const [priceValidationErr, setPriceValidationErr] = useState("");
  const [orderTypeValidationErr, setOrderTypeValidationErr] = useState("");
  const [taxProfileIdErr, setTaxProfileIdErr] = useState(false);
  const items = useSelector((state) => state.menu.category_items);
  const categories = useSelector((state) => state.menu.categories);
  const { servings } = advInfo;
  const onSave = () => {
    const { name, price, pre_name } = basicInfo;
    const { servings } = advInfo;
    const validate = items.items.findIndex((e) => e.name === name);
    if (validate === -1 || name === pre_name) {
      let validationError = false;
      setItemNameValidationErr("");
      setPriceValidationErr("");
      setOrderTypeValidationErr("");
      setTaxProfileIdErr("");
      if (name === "") {
        setItemNameValidationErr(Context.langData.please_enter_name);
        validationError = true;
      }
      if (price === "") {
        setPriceValidationErr(Context.langData.please_enter_price);
        validationError = true;
      }
      if (selectedOrderTypes.length === 0) {
        setOrderTypeValidationErr(
          Context.langData.select_atleast_one_order_type
        );
        validationError = true;
      }
      if (servings.length === 0) {
        setSnackbar({
          message: Context.langData.select_atleast_one_order_type,
          severity: "error",
        });
        validationError = true;
      }
      if (!advInfo.taxp_id && advInfo.tax_mode === "custom-tax") {
        setTaxProfileIdErr(Context.langData.tax_profile_need_to_be_selected);
        validationError = true;
      }
      if (!validationError) {
        const obj = {
          cat_id: catId,
          g_availability: basicInfo.isActive,
          dietary_attributes: null,
          is_out_of_stock: basicInfo.stock === 0 ? 1 : 0,
          name: basicInfo.name.trim(),
          image_url: basicInfo.image,
          base_price: parseToFloat(basicInfo.price),
          description: basicInfo.desc,
          isVariablePackagingCharge: false,
          serving: servings.map((a) => a.name),
          pricing_type: "variable",
          comments: [],
          bg_colour: basicInfo.bg_colour,
          font_colour: basicInfo.font_colour,
          button_style: basicInfo.button_style,
          item_type: "Food",
          // eslint-disable-next-line no-nested-ternary
          tax_mode: advInfo.tax_inclusive
            ? "tax-inclusive"
            : advInfo.tax_mode === "tax-free"
              ? "tax-free"
              : "standard",
          taxp_id: advInfo.taxp_id,
        };

        const prices_availability = [];

        selectedOrderTypes.map((s) => {
          const prices = [];
          const pkg = [];
          servings.map((ser) => {
            const ss = ser.pricings.find((p) => p.otype_id === s);
            if (ss) {
              prices.push(
                parseToFloat(ss.price) + parseToFloat(basicInfo.price)
              );
              pkg.push(0);
            }
          });
          prices_availability.push({
            otype_id: s,
            availability: true,
            packaging_charges: pkg,
            price: prices,
          });
        });
        let addons = [];
        if (basicInfo.addedModifiers.length > 0) {
          const mandatoryAddons = servings.map((s) => {
            const maddons = basicInfo.addedModifiers.map((modifier) => ({
              addon_cat_id: modifier.addon_cat_id,
              position: modifier.position,
              addon_items: modifier.addons.map((ad) => ({
                addon_id: ad.addon_item_id,
                addon_item_name: ad.addon_item_name,
                price: ad.addon_item_price
                  ? parseToFloat(ad.addon_item_price)
                  : parseToFloat(ad.price),
              })),
            }));
            return {
              serving: s.name,
              addons: maddons,
            };
          });
          addons = addons.concat(mandatoryAddons);
        }

        obj.addons = addons;
        obj.prices_availability = prices_availability;

        if (itemId) {
          obj.is_alcohol = basicInfo.isAlcohol ? "Alcohol" : "Food";
          obj.item_id = itemId;
          dispatch(editItemsByCatId(obj, onCreateCallback));
        } else {
          obj.item_type = isAlcohol ? "Alcohol" : "Food";
          dispatch(createItemsByCatId(obj, onCreateCallback));
        }
      }
    } else {
      setItemNameValidationErr(Context.langData.item_name_already_exists);
    }
  };
  const onCreateCallback = (res) => {
    if (res) {
      callback();
    }
  };
  useEffect(() => {
    if (itemId) {
      toggleIsEdit(false);
      dispatch(fetchItemInfoById({ item_id: itemId }, fetchItemByIdCallBack));
    } else {
      const otypeIds = ordertypes.map((otype) => otype.otype_id);
      setSelectedOrderTypes(otypeIds);
    }
  }, [itemId, ordertypes]);
  const fetchItemByIdCallBack = (res) => {
    const basicInfoObj = {
      name: res.name,
      pre_name: res.name,
      price: ConvertPrice(res.base_price) || "",
      desc: res.description,
      image: res.img_url,
      isActive: res.g_availability === 1,
      stock: res.is_out_of_stock === 0,
      isAlcohol: res.is_alcohol === 1,
      bg_colour: res.bg_colour || "#FFFFFF",
      font_colour: res.font_colour || "#000000",
      button_style: res.button_style || 0,
      addedModifiers: [],
    };
    const selOtypes = [];
    if (res?.prices_availability?.length > 0) {
      res.prices_availability.map((p) => {
        if (p.availability) {
          selOtypes.push(p.otype_id);
        }
      });
    }
    const servings = res.serving.map((serving, index) => ({
      name: serving.name,
      pricings: res.prices_availability.map((p) => ({
        otype_id: p.otype_id,
        otype_name: p.otype_name,
        price: p.price[index] - res.base_price,
      })),
    }));
    const maddon = [];
    if (res.addons.length > 0) {
      const addon = res.addons[0];
      addon.addons.map((ad, index) => {
        const add = addonsList.find((a) => a.addon_cat_id === ad.addon_cat_id);
        const obj = {
          addon_cat_id: ad.addon_cat_id,
          addon_cat_name: add.addon_cat_name,
          position: index,
          minimum_select: ad.minimum_select,
          maximum_select: ad.maximum_select,
          isAllowMaxSelect: ad.set_max_selection,
          minimum_quantity: ad.minimum_quantity,
          maximum_quantity: ad.maximum_quantity,
          isAllowMultiQuantity: ad.allow_multi_quantity,
          addons: ad.addon_items.map((adItem) => {
            let addonItem = {};
            if (typeof adItem === "number") {
              addonItem = add.addons.find(
                (adonItem) => adonItem.addon_item_id === adItem
              );
              return {
                addon_item_id: adItem,
                price: addonItem.addon_item_price,
                addon_item_name: addonItem.addon_item_name,
              };
            }
            addonItem = add.addons.find(
              (adonItem) => adonItem.addon_item_id === adItem.addon_id
            );
            return {
              addon_item_id: adItem.addon_id,
              price: adItem.price,
              addon_item_name: addonItem.addon_item_name,
            };
          }),
        };
        maddon.push(obj);
      });
    }
    basicInfoObj.addedModifiers = maddon;

    setSelectedOrderTypes(selOtypes);
    let advInfoObj;
    if (res.tax_mode === "tax-inclusive") {
      advInfoObj = {
        servings,
        tax_mode: res.taxp_id ? "custom-tax" : "category-tax",
        tax_inclusive: true,
        taxp_id: res.taxp_id,
      };
    } else if (res.tax_mode === "tax-free") {
      advInfoObj = {
        servings,
        tax_mode: "tax-free",
        tax_inclusive: false,
        taxp_id: res.taxp_id,
      };
    } else {
      advInfoObj = {
        servings,
        tax_mode: res.taxp_id ? "custom-tax" : "category-tax",
        tax_inclusive: false,
        taxp_id: res.taxp_id,
      };
    }
    setAdvInfo(advInfoObj);
    toggleIsEdit(true);
    setbasicInfo(basicInfoObj);
  };
  useEffect(() => {
    if (isEdit) {
      const newServings = servings.map((s) => {
        const { pricings, name } = s;
        const t = [];
        selectedOrderTypes.map((otype) => {
          let d;
          if (pricings) d = pricings.find((p) => p.otype_id === otype);
          if (d) {
            t.push(d);
          } else {
            const orderType = ordertypes.find((o) => o.otype_id === otype);
            t.push({
              otype_id: otype,
              price: 0,
              otype_name: orderType?.otype_name,
            });
          }
        });
        return { name, pricings: t };
      });
      setAdvInfo({ ...advInfo, servings: newServings });
    }
  }, [selectedOrderTypes]);
  useEffect(() => {
    dispatch(fetchAllCategories());
    dispatch(fetchAllAddons());
  }, []);
  const handleDeleteClose = (e) => {
    if (e) {
      dispatch(deleteItemsByCatId([itemId], onDelete));
    }
    toggleDeleteDialog(false);
  };
  const onDelete = (res) => {
    toggleDeleteDialog(false);
    deleteItemCallback(res);
  };

  const handleCopyClose = (e) => {
    if (e) {
      dispatch(
        copyItemById({ item_id: itemId }, (s) => {
          toggleCopyDialog(false);
          if (s) {
            copyItemCallback();
          } else {
            setSnackbar({
              message: "Failed to copy the item",
              severity: "error",
            });
          }
        })
      );
    } else {
      toggleCopyDialog(false);
    }
  };

  return (
    <RightDrawer open={open} padding={1}>
      <Grid
        container
        style={{
          minWidth: 592,
          height: "100%",
          position: "relative",
          maxWidth: 592,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}>
        <Grid container style={{ width: "100%" }}>
          <Grid
            container
            data-qaid='create_item_container'
            style={{ height: 60, padding: "0px 24px" }}
            justify='space-between'
            alignItems='center'>
            <Grid item xs={6} className={classes.h}>
              <Grid
                container
                justify='flex-start'
                alignItems='center'
                className={classes.h}>
                <MenuBookIcon
                  data-qaid='assignkitchen_setting_icon'
                  style={{ marginRight: 10 }}
                  width={22.5}
                  height={24}
                  color='#707272'
                />
                <H4
                  data-qaid='assignkitchen_settings'
                  label={Context.langData.menu_food}
                  style={{ color: "#707272" }}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.h}>
              <Grid
                container
                justify='flex-end'
                alignItems='center'
                className={classes.h}>
                <CircleClose
                  data-qaid='assignkitchen_closeicon'
                  size={20}
                  onClick={onClose}
                />
              </Grid>
            </Grid>
          </Grid>
          <div
            className='show_scroll'
            style={{
              overflow: "auto",
              // eslint-disable-next-line no-undef
              height: window.innerHeight - 235,
              width: "100%",
            }}>
            <Box>
              <Box style={{ padding: "24px 24px 0px 24px" }}>
                <AvenirBlackH4 label={Context.langData.category} />
                <SingleSelect
                  id='cat_id'
                  data-qaid='single_select_category'
                  list={categories}
                  value={catId}
                  valueKey='cat_id'
                  displayKey='name'
                  placeholder={Context.langData.select_category}
                  handleChange={(e) => {
                    setCatId(e.target.value);
                  }}
                  border={false}
                  height={56}
                />
              </Box>
              <Basic
                ordertypes={ordertypes}
                selectedOrderTypes={selectedOrderTypes}
                setSelectedOrderTypes={setSelectedOrderTypes}
                basicInfo={basicInfo}
                allModifiers={[
                  ...basicInfo.addedModifiers,
                  // ...advInfo.modifiers,
                ]}
                setbasicInfo={setbasicInfo}
                itemNameValidationErr={itemNameValidationErr}
                priceValidationErr={priceValidationErr}
                orderTypeValidationErr={orderTypeValidationErr}
                advInfo={advInfo}
                setAdvInfo={setAdvInfo}
                taxProfileIdErr={taxProfileIdErr}
                basePrice={basicInfo.price}
              />
            </Box>
          </div>
        </Grid>
        <Grid
          container
          style={{
            position: "absolute",
            backgroundColor: "white",
            height: 60,
            borderTop: "1px solid #EEE",
            bottom: 0,
            left: 0,
            alignItems: "center",
          }}>
          <Grid container wrap='nowrap' style={{ width: "100%" }}>
            {itemId && (
              <Grid container>
                <Button
                  startIcon={<DeleteIcon color='#8b0000' />}
                  style={{ marginLeft: 20 }}
                  onClick={() => toggleDeleteDialog(true)}>
                  <AvenirBlackH4 label='Delete' style={{ color: "#8b0000" }} />
                </Button>
              </Grid>
            )}
            <Grid
              container
              wrap='nowrap'
              style={{
                justifyContent: "flex-end",
              }}>
              {itemId && (
                <Button
                  startIcon={<CopyIcon />}
                  style={{ marginRight: 10 }}
                  onClick={() => toggleCopyDialog(true)}>
                  <AvenirBlackH4
                    label='Copy'
                    style={{ textDecoration: "underline", color: "#004C60" }}
                  />
                </Button>
              )}
              <Button
                variant='contained'
                style={{
                  border: "2px solid #051D33",
                  height: 40,
                  width: 103,
                  backgroundColor: "#FFF",
                  marginRight: 20,
                }}
                onClick={onClose}>
                <AvenirBlackH4 label='Cancel' />
              </Button>
              <Button
                variant='contained'
                style={{
                  backgroundColor: "#FFB600",
                  height: 40,
                  width: 90,
                  marginRight: 20,
                }}
                onClick={onSave}>
                <AvenirBlackH4 label={Context.langData.save} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {snackbar && (
        <SnackBar
          data-qaid='categories_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      {showDeleteDialog && (
        <AlertDialog
          showDialog={showDeleteDialog}
          body='Are you sure you want to delete this item from your menu?'
          heading='Delete item'
          IsError={false}
          showExtra={false}
          handleClose={handleDeleteClose}
        />
      )}
      {showCopyDialog && (
        <AlertDialogDelete
          showDialog={showCopyDialog}
          heading='Copy item'
          body={Context.langData.copy_item_message}
          IsError={false}
          showExtra={false}
          handleClose={handleCopyClose}
          buttonLabel={Context.langData.copy}
        />
      )}
    </RightDrawer>
  );
};
export default CreateItem;
