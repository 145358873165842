import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { Grid } from "@material-ui/core";
import {
  fetchOrderTypes,
  fetchPaymentTypes,
  fetchTaxes,
  fetchOrderTypeDelivery,
  fetchDietaryAttributes,
} from "../../../redux/actions";

const OrderTypes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch actions to fetch necessary data
    dispatch(fetchOrderTypes());
    dispatch(fetchPaymentTypes());
    dispatch(fetchTaxes());
    dispatch(fetchOrderTypeDelivery());
    dispatch(fetchDietaryAttributes());
  }, [dispatch]);

  return (
    <div style={{ borderTop: "1px solid #EEEEEE" }}>
      <Grid container direction='row'>
        <Grid item xs={12} lg={12} xl={12}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderTypes;
