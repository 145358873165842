import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useLDClient } from "launchdarkly-react-client-sdk";
import {
  Box,
  Hidden,
  List,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { FeatureKeys } from "../../../enums";
import {
  Dashboard as DashboardIcon,
  Menu as MenuBookIcon,
  Report as AssessmentIcon,
  User as AccountCircleOutlinedIcon,
  Settings as SettingsIcon,
  Transaction as SwapHorizontalCircleIcon,
  InventoryIcon,
  BetaIcon,
  PettyCashIcon,
} from "../../../components/SVG";
import MyContext from "../../../Provider/MyContext";

import MiniDrawer from "./MiniDrawer";
import NavItem from "./NavItem";

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 80,
    maxWidth: "100%",
    overflow: "auto",
    paddingBottom: "20px",
    transition: "width 0.3s ease-in-out",
  },
  mobileDrawerExpanded: {
    width: 200,
  },
  desktopDrawer: {
    width: 200,
    top: 64,
    height: "calc(100vh - 70px)",
    overflowY: "auto",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  toolbar: {
    height: "calc(100vh - 70px)",
    backgroundColor: "#fafafa",
    overflowY: "auto",
    paddingBottom: "20px",
  },
}));

const NavBar = ({ openMobile }) => {
  const Context = useContext(MyContext);
  const selectedRestaurant = useSelector(
    (state) => state.loginReducer.selectedRestaurant
  );
  const Permissions = useSelector((state) => state.profileReducers.permissions);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 1366px)");

  const items = [
    {
      id: 1,
      href: "/home/dashboard",
      icon: DashboardIcon,
      title: Context.langData.dashboard,
    },
    {
      id: 2,
      icon: SwapHorizontalCircleIcon,
      title: Context.langData.transations,
      href: "/home/transactions",
    },
    {
      id: 3,
      icon: AssessmentIcon,
      title: Context.langData.reports,
      href: "/home/reports/sales-reports",
      subRoutes: [
        { id: 31, href: "/home/reports/sales-reports", title: "Sales Reports" },
        {
          id: 32,
          href: "/home/reports/inventory-reports",
          title: "Inventory Reports",
        },
        {
          id: 33,
          href: "/home/reports/employee-reports",
          title: "Employee Reports",
        },
      ],
    },
    {
      id: 4,
      icon: MenuBookIcon,
      title: Context.langData.menu,
      href: "/home/menu/categories",
      subRoutes: [
        { id: 41, href: "/home/menu/categories", title: "Categories" },
        {
          id: 42,
          href: "/home/menu/item-catalogue",
          title: Context.langData.item_catalogue,
        },
        {
          id: 43,
          href: "/home/menu/modifiers",
          title: Context.langData.modifiers,
        },
        {
          id: 44,
          href: "/home/menu/order-type-list",
          title: Context.langData.order_types,
        },

        {
          id: 45,
          href: "/home/menu/dietary-attributes",
          title: Context.langData.dietary_restrictions,
        },
      ],
    },
    {
      id: 5,
      icon: AccountCircleOutlinedIcon,
      title: Context.langData.profiles,
      href: "/home/profile",
    },
    {
      id: 6,
      icon: InventoryIcon,
      title: Context.langData.inventory,
      href: "/home/inventory",
      iconBeta: BetaIcon,
    },
    {
      id: 7,
      icon: PettyCashIcon,
      title: Context.langData.pettycash,
      href: "/home/pettyCash",
      // subRoutes: [
      //   {
      //     id: 71,
      //     href: "/home/pettyCash/expenses",
      //     title: Context.langData.expenses,
      //   },
      //   {
      //     id: 72,
      //     href: "/home/pettyCash/categories",
      //     title: Context.langData.categories,
      //   },
      // ],
    },
    {
      id: 9,
      icon: SettingsIcon,
      title: Context.langData.settings,
      href: "/home/settings/discounts",
      subRoutes: [
        {
          id: 91,
          href: "/home/settings/discounts",
          title: Context.langData.discounts,
        },
        {
          id: 92,
          href: "/home/settings/shifts",
          title: Context.langData.shifts,
        },
        {
          id: 93,
          href: "/home/settings/printers",
          title: Context.langData.printers,
        },
        {
          id: 94,
          href: "/home/settings/kitchens",
          title: Context.langData.kitchen_creation,
        },
        {
          id: 95,
          href: "/home/settings/delivery-options",
          title: Context.langData.delivery_options,
        },
        {
          id: 96,
          href: "/home/settings/end-of-day-settings",
          title: Context.langData.reports,
        },

        {
          id: 98,
          href: "/home/settings/taxes",
          title: Context.langData.taxes,
        },
        {
          id: 99,
          href: "/home/settings/tax-profiles",
          title: Context.langData.taxProfiles,
        },
        {
          id: 100,
          href: "/home/settings/terminals",
          title: Context.langData.terminals,
        },
      ],
    },
  ];

  let filteredItems = [...items];

  // Check if inventory is available for the restaurant which is controlled by feature flag
  const featureFlagClient = useLDClient();
  if (featureFlagClient && selectedRestaurant?.rest_id) {
    const featureFlagContext = {
      kind: "restaurant",
      key: "restaurant",
      restId: selectedRestaurant.rest_id,
    };
    featureFlagClient.identify(featureFlagContext, null, () => {});
    const inventoryFlagValue = featureFlagClient.variation(
      FeatureKeys.Inventory,
      true
    );
    const pettyCashFlagValue = featureFlagClient.variation(
      FeatureKeys.PettyCash,
      true
    );
    const kitchenTicketFlagValue = featureFlagClient.variation(
      FeatureKeys.KitchenTicket,
      false
    );

    if (!inventoryFlagValue) {
      filteredItems = filteredItems.filter((item) => item.id !== 6);
    }
    if (!pettyCashFlagValue) {
      filteredItems = filteredItems.filter((item) => item.id !== 7);
    }
    if (kitchenTicketFlagValue) {
      filteredItems = filteredItems.map((item) => {
        if (item.id === 9) {
          const updatedSubRoutes = [...item.subRoutes];
          const insertIndex = updatedSubRoutes.findIndex(
            (route) => route.id === 96
          );
          const newSubRoute = {
            id: 97,
            href: "/home/settings/kitchen-guest-receipt-settings",
            title: Context.langData.kitchen_tickets,
          };

          if (insertIndex !== -1) {
            updatedSubRoutes.splice(insertIndex, 0, newSubRoute);
          } else {
            updatedSubRoutes.push(newSubRoute);
          }
          return { ...item, subRoutes: updatedSubRoutes };
        }
        return item;
      });
    }
  }
  useEffect(() => {
    if (
      Permissions &&
      Permissions.permissions &&
      Permissions.permissions.length > 0
    ) {
      let permissionsIds = [];
      if (selectedRestaurant && selectedRestaurant.status === 1) {
        permissionsIds =
          (selectedRestaurant && selectedRestaurant.permission) ||
          selectedRestaurant.permission;
        const portalPermissions = Permissions.permissions.filter(
          (e) => e.application_id === 1
        );
        const userPermissions = [];
        if (permissionsIds) {
          Object.keys(permissionsIds).forEach((key) => {
            if (portalPermissions.length > 0) {
              const permissionItem = portalPermissions.filter(
                (e) => e.permission_id === parseInt(key, 10)
              );
              if (permissionItem.length > 0) {
                const permissionObj = {
                  ...permissionItem[0],
                  status: permissionsIds[key] === 1,
                };
                userPermissions.push(permissionObj);
              }
            }
          });
        }
        dispatch({ type: "SET_USER_PERMISSIONS", payload: userPermissions });
      } else {
        navigate("/noAccess");
      }
    }
  }, [Permissions]);
  const content = (
    <Box
      height='100%'
      display='flex'
      flexDirection='column'
      className={classes.toolbar}>
      <Box>
        <List component='nav'>
          {filteredItems.map((item) => (
            <NavItem
              key={item.title}
              item={item}
              isDrawerOpen={openMobile}
              showText={openMobile}
              isTablet={isTablet}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <Hidden>
      <MiniDrawer classes1={classes} open={openMobile} isTablet={isTablet}>
        {content}
      </MiniDrawer>
    </Hidden>
  );
};

NavBar.propTypes = {
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  openMobile: false,
};

export default NavBar;
