import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles } from "@material-ui/core";
import { DotTextMedium, AvenirBlackH5 } from "../../../../utils/text";
import {
  ButtonMenu,
  DraggableList,
  SnackBar,
  AlertDialog,
} from "../../../../components";
import { MenuIconTwoLines } from "../../../../components/SVG";
import MyContext from "../../../../Provider/MyContext";
import {
  fetchModifierItem,
  editAddonCategories,
  deleteAddonCategories,
} from "../../../../redux/actions";
import ModifierGroupCreateEdit from "../../OrderTypes/ModifierGroupCreateEdit";

const useStyles = makeStyles(() => ({
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
}));

const ModifierList = ({ modifiers, onDeleteModifier, onDragChange }) => {
  const modifierItem = useSelector((state) => state.menu.modifierItem);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [snackBar, setSnackBar] = useState(false);
  const Context = useContext(MyContext);

  const onClickAction = (rowValue) => {
    setOpen(true);
    dispatch(fetchModifierItem({ addon_cat_id: rowValue.addon_cat_id }));
  };
  function editCategoriesCallback(isSuccess, msg) {
    setOpen(false);
    if (isSuccess) {
      setSnackBar({
        message: Context.langData.modifier_group_edit_succ,
        severity: "success",
      });
    } else {
      setSnackBar({
        message: msg.message,
        severity: "error",
      });
    }
  }
  function deleteCategoriesCallback(isSuccess, data) {
    setOpen(false);
    if (isSuccess) {
      setSnackBar({
        message: Context.langData.modifier_group_delete_succ,
        severity: "success",
      });
    } else {
      setSnackBar({
        message: data.status,
        severity: "error",
      });
    }
  }
  const handleDeleteModifier = (payload) => {
    dispatch(
      deleteAddonCategories([payload.addon_cat_id], deleteCategoriesCallback)
    );
  };
  const handleEditModifier = (payload) => {
    dispatch(editAddonCategories(payload, editCategoriesCallback));
  };
  const renderItem = (item, index) => (
    <ModifierItem
      key={index}
      onEdit={onClickAction}
      onDelete={() => onDeleteModifier(index)}
      item={item}
    />
  );
  const handleDragEnd = (startIndex, endIndex) => {
    const reorderedItems = Array.from(modifiers);
    const [removed] = reorderedItems.splice(startIndex, 1);
    reorderedItems.splice(endIndex, 0, removed);
    const finalReorder = reorderedItems.map((item, index) => ({
      ...item,
      position: index,
    }));
    onDragChange(finalReorder);
  };
  return (
    <Grid container>
      <DraggableList
        items={modifiers}
        onDragEnd={handleDragEnd}
        renderItem={renderItem}
        isEditing
        gridItemProps={{
          display: "flex",
          flexDirection: "column",
        }}
        customDraggableStyle={{ padding: 0 }}
        position='position'
      />
      {open && (
        <ModifierGroupCreateEdit
          data-qaid='addon_create_categories_snackbar'
          initialValues={modifierItem}
          open={open}
          onClose={() => setOpen(false)}
          handleDelete={handleDeleteModifier}
          handleEdit={handleEditModifier}
        />
      )}
      {snackBar && (
        <SnackBar
          data-qaid='addon_categories_snackbar'
          open
          setOpen={() => setSnackBar(null)}
          severity={snackBar.severity}
          message={snackBar.message}
        />
      )}
    </Grid>
  );
};

const ModifierItem = React.memo(({ item, onDelete, onEdit }) => {
  const classes = useStyles();
  const Context = useContext(MyContext);
  const [openDialog, setOpenDialog] = useState(false);

  const buttonMenuData = [
    {
      id: 1,
      name: Context.langData.edit_modifier_group,
    },
    {
      id: 2,
      name: Context.langData.remove_modifier_group,
    },
  ];
  const callBack = (id) => {
    if (id === 1) {
      onEdit(item);
    }
    if (id === 2) {
      setOpenDialog(true);
    }
  };
  const onModifierDelete = () => {
    setOpenDialog(false);
    onDelete();
  };
  return (
    <Grid
      container
      className={classes.flexRow}
      style={{
        justifyContent: "space-between",
        borderBottom: "1px solid #EEE",
        width: "100%",
        padding: 8,
      }}>
      <Grid
        className={classes.flexRow}
        style={{
          width: "50%",
          justifyContent: "flex-start",
          gap: 20,
        }}>
        <MenuIconTwoLines />
        <Grid className={classes.flexColumn}>
          <DotTextMedium
            label={`${item.addon_cat_name}`}
            style={{ fontSize: 16 }}
          />
          <AvenirBlackH5
            label={`${
              item.addons ? item.addons?.length : 0
            } ${Context.langData.options}. ${Context.langData.select} ${
              item.minimum_select > 0
                ? `${Context.langData.min} ${item.minimum_select}`
                : `${Context.langData.max} ${item.maximum_select}`
            }, ${
              item.minimum_select > 0
                ? `${Context.langData.max} ${item.maximum_select}`
                : `${Context.langData.up_to} ${item.minimum_select} ea`
            }.`}
          />
        </Grid>
      </Grid>
      <Grid
        className={classes.flexRow}
        style={{
          width: "50%",
          justifyContent: "flex-end",
          gap: 20,
        }}>
        <DotTextMedium
          label={
            item.minimum_select > 0
              ? Context.langData.Mandatory
              : Context.langData.Optional
          }
          style={{ fontSize: 14 }}
        />
        <ButtonMenu
          type='ellipsis'
          menuItems={buttonMenuData}
          callBack={callBack}
        />
      </Grid>
      <AlertDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title={`${Context.langData.remove_modifier_group}?`}
        bodyText={Context.langData.this_will_modifier_group}
        secondaryButtonText={Context.langData.cancel}
        primaryButtonText={Context.langData.yes_remove}
        onPrimaryAction={onModifierDelete}
        onSecondaryAction={() => {
          setOpenDialog(false);
        }}
      />
    </Grid>
  );
});

export default React.memo(ModifierList);
