import React, { useMemo, useState, useContext } from "react";
import { Grid, FormHelperText } from "@material-ui/core";
import { useDispatch } from "react-redux";
import InputText from "../../../../components/InputText";
import MyContext from "../../../../Provider/MyContext";
import {
  H4,
  AvenirBlackH3,
  AvenirBlackH4,
  DotText,
  DotTextMedium,
  H5,
} from "../../../../utils/text";
import {
  CustomCheckbox,
  OnOffSwitch,
  ColorSelector,
} from "../../../../components";
import CirclePlus from "../../../../components/SVG/CirclePluse";
import { ConvertPrice, validateAlphaNumeric } from "../../../../utils/util";
import PencileIcon from "../../../../components/SVG/PencileIcon";
import { fetchImageUrl, putImageUrl } from "../../../../redux/actions";
import InfoOutlined from "../../../../components/SVG/InfoOutlined";
import ModifierList from "./ModifierList";
import Modifier from "./Modifier";
import ChangeImage from "./ChangeImage";
import CustomTaxMode from "./customTaxMode";
import VariablePricing from "./VariablePricing";

const Basic = ({
  ordertypes,
  setSelectedOrderTypes,
  selectedOrderTypes,
  setbasicInfo,
  basicInfo,
  allModifiers,
  itemNameValidationErr,
  priceValidationErr,
  orderTypeValidationErr,
  advInfo,
  setAdvInfo,
  taxProfileIdErr,
}) => {
  const [showModifierModal, toggleModifierModal] = useState(false);
  const [showVariable, togglevariable] = useState(false);
  const [editAddonItem, setAddonItem] = useState(null);
  const Context = useContext(MyContext);
  const dispatch = useDispatch();

  const onChangeFields = (field, data) => {
    if (field === "name") {
      data = validateAlphaNumeric(data);
    }
    setbasicInfo({ ...basicInfo, [field]: data });
  };

  const onServingChange = (data) => {
    setAdvInfo({ ...advInfo, servings: data });
    togglevariable(false);
  };

  const onModifierAdd = (modifier) => {
    toggleModifierModal(false);
    const index = basicInfo.addedModifiers.findIndex(
      (e) => e.addon_cat_id === modifier.addon_cat_id
    );
    if (editAddonItem == null) {
      if (index === -1)
        onChangeFields("addedModifiers", [
          ...basicInfo.addedModifiers,
          modifier,
        ]);
    } else {
      const temp = [...basicInfo.addedModifiers];
      temp[index] = modifier;
      onChangeFields("addedModifiers", temp);
    }
    setTimeout(() => {
      setAddonItem(null);
    }, 200);
  };
  const onDeleteModifier = (index) => {
    let temp = [...basicInfo.addedModifiers];
    temp = [...temp.slice(0, index), ...temp.slice(index + 1)].map(
      (modifierGroup, index) => ({ ...modifierGroup, position: index })
    );
    onChangeFields("addedModifiers", temp);
  };
  const onDragChange = (order) => {
    let temp = [...basicInfo.addedModifiers];
    temp = [...order];
    onChangeFields("addedModifiers", temp);
  };

  const onOrderTypeSelect = (e, id) => {
    let temp = [...selectedOrderTypes];
    const index = temp.indexOf(id);

    if (index !== -1) {
      temp = [...temp.slice(0, index), ...temp.slice(index + 1)];
    } else {
      temp = [...temp, id];
    }
    setSelectedOrderTypes(temp);
  };
  const onImageChange = (image, file) => {
    if (file) {
      dispatch(
        fetchImageUrl(
          {
            file_name: image,
            content_type: file.type,
            image_loc: "menu",
          },
          (response) => {
            completeLogo(response, file, 1);
          }
        )
      );
    } else {
      onChangeFields("image", null);
    }
  };
  const completeLogo = (response, file) => {
    const obj = {};
    const url = response.upload_url.split("?")[0];
    obj.ContentType = file.type;
    obj.Body = file;
    dispatch(
      putImageUrl(
        response.upload_url,
        obj,
        () => {
          onChangeFields("image", url);
        },
        response.storage === "azure"
      )
    );
  };

  const handleOnBlurChange = (data) => {
    if (basicInfo.price !== "") {
      setbasicInfo({ ...basicInfo, price: ConvertPrice(data) });
    }
  };

  const handleItemColorSelection = (val) => {
    setbasicInfo({
      ...basicInfo,
      ...val,
    });
  };

  const otypes = useMemo(
    () =>
      selectedOrderTypes.map((s) => {
        const d = ordertypes.find((o) => o.otype_id === s);

        return {
          otype_name: d?.otype_name,
          otype_id: s,
        };
      }),
    [ordertypes, selectedOrderTypes]
  );

  return (
    <Grid
      container
      className='basic_menu_scroll'
      style={{ position: "relative" }}>
      <Grid container style={{ paddingBottom: 50 }}>
        <Grid style={{ padding: "0px 24px", width: `100%` }}>
          <ColorSelector
            name={basicInfo.name}
            onSelector={handleItemColorSelection}
            disableRadio={false}
            style={{ marginTop: 20, marginBottom: 20 }}
            itemPrice={basicInfo.price ? `${`$${basicInfo.price}`}` : ""}
            initialColors={{
              bg_colour: basicInfo?.bg_colour,
              font_colour: basicInfo?.font_colour,
              button_style: basicInfo?.button_style,
            }}
          />
        </Grid>

        <Grid
          container
          direction='row'
          style={{ marginTop: 30, padding: "0px 24px" }}>
          <Grid item xs={8}>
            <InputText
              onChange={(e) => onChangeFields("name", e.target.value)}
              value={basicInfo.name}
              placeholder='Item name'
              maxLength={64}
              id='item_name'
              multiline
              rows={1}
              error={
                itemNameValidationErr?.length > 0 ? itemNameValidationErr : null
              }
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={3}>
            <InputText
              onChange={(e) =>
                onChangeFields("price", e.target.value.replace(/[^0-9.]/g, ""))
              }
              value={`${`$${basicInfo.price}`}`}
              placeholder='Price'
              maxLength={8}
              onBlur={() => {
                handleOnBlurChange(basicInfo.price);
              }}
              // startAdornment={<InputAdornment position="start">$</InputAdornment>}
              inputProps={{ step: 0.01, min: 0 }}
              error={priceValidationErr?.length > 0 ? priceValidationErr : null}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          alignItems='center'
          style={{ marginTop: 20, paddingLeft: 50 }}>
          <Grid
            data-qaid='hawkeye_body_grid'
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}>
            <H4
              data-qaid='hawkeye_label'
              label='Active item'
              style={{ marginTop: 15 }}
            />
            <OnOffSwitch
              data-qaid='hawkeye_onoffswith'
              onChange={() => onChangeFields("isActive", !basicInfo.isActive)}
              show={basicInfo.isActive}
            />
          </Grid>
          <Grid
            data-qaid='hawkeye_body_grid'
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              paddingLeft: 30,
              paddingRight: 20,
            }}>
            <H4
              data-qaid='hawkeye_label'
              label='In stock'
              style={{ marginTop: 15 }}
            />
            <OnOffSwitch
              data-qaid='hawkeye_onoffswith'
              onChange={() => onChangeFields("stock", !basicInfo.stock)}
              show={basicInfo.isActive ? basicInfo.stock : false}
            />
          </Grid>
        </Grid>

        {ordertypes.map((otype) => (
          <Grid
            container
            direction='row'
            alignItems='center'
            style={{ padding: "0px 50px" }}>
            <Grid item xs={6}>
              <DotText label={otype.name} style={{ marginRight: 60 }} />
            </Grid>
            <Grid item xs={6}>
              <CustomCheckbox
                disabled={!basicInfo.isActive}
                checkedColor={basicInfo.isActive ? "#004C60" : "#C3C3C3"}
                checked={selectedOrderTypes.indexOf(otype.otype_id) !== -1}
                onChange={(e) =>
                  onOrderTypeSelect(e.target.checked, otype.otype_id)
                }
              />
            </Grid>
          </Grid>
        ))}
        {orderTypeValidationErr && (
          <Grid
            container
            direction='row'
            alignItems='center'
            style={{ padding: "0px 50px" }}>
            <FormHelperText
              data-qaid='inputtext_formhelpertext'
              id='order-type-text'
              style={{
                color: "#AB1212",
                display: "flex",
                marginLeft: 0,
                marginTop: 8,
              }}>
              <InfoOutlined
                width={16}
                height={16}
                color='#AB1212'
                style={{ marginRight: 6 }}
              />
              <H5
                label={orderTypeValidationErr}
                style={{ color: "#AB1212 " }}
              />
            </FormHelperText>
          </Grid>
        )}
        <Grid
          container
          direction='row'
          style={{ marginTop: 20, padding: 20, backgroundColor: "#F3FAFD" }}>
          <Grid item xs={6}>
            <InputText
              onChange={(e) => onChangeFields("desc", e.target.value)}
              value={basicInfo.desc}
              placeholder='Description'
              height={150}
              maxLength={256}
              multiline
              inputProps={{ step: 0.01, min: 0.0 }}
              rows={6}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 10 }}>
            <AvenirBlackH4 label='Image' style={{ marginLeft: 5 }} />
            <ChangeImage image={basicInfo.image} onChange={onImageChange} />
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            marginTop: 10,
            padding: 20,
            borderBottom: "1px solid #EEE",
            paddingBottom: 20,
          }}>
          <Grid item xs={8}>
            <AvenirBlackH3 label='Variable pricing (size & order type)' />
          </Grid>
          <Grid item xs={4}>
            <Grid
              container
              justify='flex-end'
              onClick={() => togglevariable(true)}>
              <PencileIcon style={{ marginRight: 5 }} />
              <AvenirBlackH4 label='Edit' style={{ color: "#004C60" }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 10, padding: 20 }}>
          {advInfo.servings?.length > 0 && (
            <Grid container style={{ width: 430, overflow: "auto" }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  marginBottom: 10,
                }}>
                <span style={{ width: 170, display: "inline-block" }}>
                  <H4 label='Size' style={{ color: "#707272" }} />
                </span>
                {otypes.map((ot) => (
                  <span style={{ width: 100, display: "inline-block" }}>
                    <H4 label={ot.otype_name} style={{ color: "#707272" }} />
                  </span>
                ))}
              </div>
              {advInfo.servings.map((serving, indx) => (
                <div
                  // eslint-disable-next-line react/no-unknown-property
                  container
                  style={{ display: "flex", flexWrap: "nowrap", marginTop: 15 }}
                  key={indx}>
                  <span style={{ width: 170, display: "inline-block" }}>
                    <H4
                      label={serving.name}
                      style={{ width: 160, wordBreak: "break-all" }}
                    />
                  </span>
                  {otypes.map((ot) => {
                    const price = serving.pricings.find(
                      (p) => p.otype_id === ot.otype_id
                    );
                    return (
                      <span style={{ width: 100, display: "inline-block" }}>
                        <H4 label={`+$${ConvertPrice(price?.price)}`} />
                      </span>
                    );
                  })}
                </div>
              ))}
            </Grid>
          )}
        </Grid>
        <Grid container style={{ marginTop: 10, padding: 20 }}>
          <Grid style={{ width: "80%", marginBottom: 10 }}>
            <DotTextMedium
              label={Context.langData.modifier_group}
              style={{ fontSize: 16 }}
            />
          </Grid>
          <ModifierList
            modifiers={basicInfo.addedModifiers}
            onDeleteModifier={onDeleteModifier}
            onDragChange={onDragChange}
          />
        </Grid>
        <Grid
          container
          justify='center'
          alignItems='center'
          style={{
            marginTop: 20,
          }}>
          <Grid
            onClick={() => toggleModifierModal(true)}
            style={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
            }}>
            <CirclePlus color='#004C60' width={21} height={21} />
            <AvenirBlackH4
              label={Context.langData.add_new_modifier}
              style={{ color: "#004c60", marginLeft: 10 }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems='center'
          style={{
            marginTop: 20,
            padding: 20,
          }}>
          <CustomTaxMode
            info={advInfo}
            setInfo={setAdvInfo}
            taxProfileIdErr={taxProfileIdErr}
          />
        </Grid>
      </Grid>
      {showVariable && (
        <VariablePricing
          show={showVariable}
          otypes={otypes}
          servings={advInfo.servings}
          onServingChange={onServingChange}
          onClose={() => togglevariable(false)}
        />
      )}
      {showModifierModal && (
        <Modifier
          show={showModifierModal}
          onSave={onModifierAdd}
          item={editAddonItem}
          addedModifiers={allModifiers}
          onClose={() => {
            setAddonItem(null);
            toggleModifierModal(false);
          }}
        />
      )}
    </Grid>
  );
};

export default Basic;
