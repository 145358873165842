import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import clsx from "clsx";
import {
  Box,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  makeStyles,
  Icon,
} from "@material-ui/core";

import { RightArrow } from "../../../components/SVG";
import SubRouteNavItem from "./SubRouteNavItem";

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "AvenirLT",
    fontSize: 14,
    fontWeight: 400,
    transition: "font-weight 0.2s ease-in-out",
    "&:hover": { fontWeight: 900 },
    "&.active": { fontWeight: 900 },
  },
  btn: {
    fontFamily: "AvenirLT",
    height: 50,
    margin: 10,
    width: "93%",
    "&:hover": {
      borderLeft: "4px solid #004C60",
      fontWeight: 900,
      fontSize: "1.2em",
      borderRadius: "4px",
      width: "93%",
    },
    "&.active": {
      backgroundColor: "#C8E6F1",
      borderLeft: "4px solid #004C60",
      fontWeight: 900,
      fontSize: "1.2em",
      borderRadius: "4px",
      width: "93%",
    },
    "&:hover $title": { fontWeight: 900 },
  },
  tabletBtn: {
    fontFamily: "AvenirLT",
    height: 50,
    margin: 10,
    width: "82%",
    color: "#051D33",
    paddingLeft: 8,
    paddingRight: 8,
    "&:hover": {
      borderLeft: "4px solid #004C60",
      fontWeight: 900,
      fontSize: "1.2em",
      borderRadius: "4px",
    },
    "&.active": {
      backgroundColor: "#C8E6F1",
      borderLeft: "4px solid #004C60",
      fontWeight: 900,
      fontSize: "1.2em",
      borderRadius: "4px",
    },
    "&:hover $title": { fontWeight: 900 },
  },
  icon: {
    minWidth: 35,
    width: 20,
    height: "100%",
    color: "#004C60",
    marginRight: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrowIcon: {
    transition: "transform 0.3s ease-in-out",
    fontSize: "inherit",
    display: "block",
    width: "auto",
    height: "auto",
  },
  arrowRotated: { transform: "rotate(180deg)" },
  sideBar: {
    maxHeight: "70vh",
    height: "calc(100vh-100px)",
    overflowY: "auto",
    position: "fixed",
    background: "#FFF",
    border: "1px solid #ccc",
    boxShadow: "0px 0px 6px 0px rgba(0, 0, 0, 0.20)",
    zIndex: 1000,
    minWidth: "200px",
    borderRadius: "5px",

    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#004C60",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#FAFAFA",
    },
  },
}));

const NavItem = ({ item, showText, isTablet }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/");
  const locationHrefText = `/${path[1]}/${path[2]}`;
  const parsedPath = item.href.split("/");
  const parsedHrefText = `/${parsedPath[1]}/${parsedPath[2]}`;
  const [isHovered, setIsHovered] = useState(false);
  const [sidebarPosition, setSidebarPosition] = useState({ left: 0, top: 0 });
  const navItemRef = useRef(null);

  const isActive = locationHrefText === parsedHrefText;

  const NavIcon = item.icon;

  const handleMouseEnter = (e) => {
    if (navItemRef.current) {
      const rect = navItemRef.current.getBoundingClientRect();
      setSidebarPosition({
        left: rect.right - 5,
        top: e.target.textContent !== "Settings" ? rect.top : rect.top - 300,
      });
    }
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box
      ref={navItemRef}
      onMouseEnter={(e) => {
        handleMouseEnter(e);
      }}
      onMouseLeave={handleMouseLeave}
      style={{ position: "relative" }}
      aria-expanded={isHovered}>
      {!isTablet && (
        <ListItem
          button
          className={clsx(classes.btn, { active: isActive })}
          style={{ color: "#051D33", paddingLeft: 8, paddingRight: 8 }}
          onClick={() => navigate(item.href)}>
          <ListItemIcon
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              minWidth: "40px",
            }}>
            <span
              className={
                isActive ? clsx(classes.icon, "active") : clsx(classes.icon)
              }>
              <NavIcon
                color={isActive ? "#051d33" : "#004C60"}
                width={20}
                height={20}
              />
            </span>
          </ListItemIcon>
          <ListItemText
            primary={
              <span className={clsx(classes.title, { active: isActive })}>
                {item.title}
              </span>
            }
          />
          {item.subRoutes && (
            <Icon className={clsx(classes.arrowIcon)}>
              {item.subRoutes && <RightArrow />}
            </Icon>
          )}
        </ListItem>
      )}

      {isTablet && (
        <ListItem
          button
          className={clsx(classes.tabletBtn, { active: isActive })}
          style={{ color: "#051D33" }}
          onClick={() => navigate(item.href)}>
          <ListItemIcon
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              minWidth: "40px",
            }}>
            <span
              className={
                isActive ? clsx(classes.icon, "active") : clsx(classes.icon)
              }>
              <NavIcon
                color={isActive ? "#051d33" : "#004C60"}
                width={20}
                height={20}
              />
            </span>
          </ListItemIcon>
          {showText && (
            <ListItemText
              primary={
                <span className={clsx(classes.title, { active: isActive })}>
                  {item.title}
                </span>
              }
            />
          )}
          {/* <Icon
            className={clsx(classes.arrowIcon, {
              [classes.arrowRotated]: isActive,
            })}>
            {item.subRoutes && <RightArrow />}
          </Icon> */}
          {item.subRoutes && (
            <Icon className={clsx(classes.arrowIcon)}>
              {item.subRoutes && <RightArrow />}
            </Icon>
          )}
        </ListItem>
      )}
      {/* Side Box for Subroutes */}
      {isHovered && item.subRoutes && (
        <Box
          className={classes.sideBar}
          style={{
            left: sidebarPosition.left,
            top: sidebarPosition.top,
          }}
          tabIndex={isHovered ? 0 : -1}
          data-qaid='side-nav'>
          <List style={{ color: "#051D33", paddingLeft: 8, paddingRight: 8 }}>
            {item.subRoutes.map((subRoute) => (
              <SubRouteNavItem
                id={subRoute.id}
                href={subRoute.href}
                title={subRoute.title}
              />
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

NavItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  showText: PropTypes.bool,
  isTablet: PropTypes.bool,
};

export default NavItem;
