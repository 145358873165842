import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, Menu, MenuItem } from "@material-ui/core";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { AvenirBlackH4, H6, H5 } from "../../../utils/text";
import MyContext from "../../../Provider/MyContext";
import {
  fetchItemsByCatId,
  fetchItemTypes,
  fetchAllCategories,
  deleteItemsByCatId,
  duplicateItemsByCatId,
} from "../../../redux/actions";
import NewDataTable from "../../../components/NewDataTable";
import CreateItem from "../items/create/CreateItem";
import CombosModal from "../items/combos";
import PizzasModal from "../items/pizzas";
import styles from "../../../styles/index";
import { BetaIcon, PluseIcon, CircleClose } from "../../../components/SVG";
import {
  ButtonMenu,
  SnackBar,
  Dialog,
  SearchBar as TopSearchBar,
  SingleSelect,
  AlertDialogDelete,
  AlertDialog,
} from "../../../components";
import { debounce, validateAlphaNumeric } from "../../../utils/util";
import { FeatureKeys } from "../../../enums";
import { useDrawers } from "../../../custom-hooks";
import ItemAssignAddons from "./itemAssignAddons";
import ItemUnassignAddons from "./itemUnassignAddons";
import ItemAssignCategories from "./itemAssignCategories";
import AssignPriceToItems from "./AssignPriceToItems";

const ItemCatalogue = () => {
  const style = styles();
  const dispatch = useDispatch();
  const Context = useContext(MyContext);
  const itemsList = useSelector((state) => state.menu.category_items);
  const categories = useSelector((state) => state.menu.categories);
  const itemTypes = useSelector((state) => state.menu.itemTypes);
  const [params, setParams] = useState({ limit: 25, offset: 0 });
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [rowData, setRowData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedId, setSelectedId] = useState([]);
  const { drawers, toggleDrawer } = useDrawers({
    isAlcohol: false,
    createOpenFLag: false,
    editOpenFlag: false,
    isOpenComboModal: false,
    isOpenPizzaModal: false,
    openAssignCategories: false,
    openAssignAddons: false,
    openUnassignAddons: false,
    openAssignAddonsCompleteDialog: false,
    openUnassignAddonsCompleteDialog: false,
    openReassignCategoryCompleteDialog: false,
    openMultipleItemPriceUpdateDialog: false,
    showDialog: false,
    showDuplicateDialog: false,
    openPriceChangeModal: false,
  });
  const [snackbar, setSnackbar] = useState(null);
  const [currentCatName, setCurrentCatName] = useState(null);
  const [failedItemsOfUpdatePrice, setFailedItemsOfUpdatePrice] = useState([]);
  const columns = [
    {
      field: "name",
      headerName: Context.langData.itemName,
    },
    {
      field: "price",
      headerName: Context.langData.Price,
      isNumber: true,
    },
    {
      field: "category",
      headerName: Context.langData.category,
    },
    {
      field: "available",
      headerName: Context.langData.status,
      statusFlag: true,
    },
    {
      field: "in_stock",
      headerName: Context.langData.in_stock,
    },
    {
      field: "tax_profile",
      headerName: Context.langData.taxProfile,
    },
    {
      field: "modifiers",
      headerName: Context.langData.modifiers,
    },
  ];
  const itemtypeName = [
    { id: 1, value: "Individual food item", isBeta: false },
    { id: 2, value: "Alcoholic beverage", isBeta: false },
    { id: 3, value: "Combos", isBeta: false },
    { id: 4, value: "Build-your-own item", isBeta: true },
  ];

  // check if build your own item is available for the restaurant which is controlled by feature flag
  const featureFlagClient = useLDClient();
  if (featureFlagClient) {
    const flagValue = featureFlagClient.variation(
      FeatureKeys.BuildYourOwnItem,
      true
    );
    if (flagValue === false) {
      // Remove build your own item from itemtypeName
      const byo = (element) => element.id === 4;
      const index = itemtypeName.findIndex(byo);
      itemtypeName.splice(index, 1);
    }
  }

  const ButtonMenuData = [
    {
      id: 1,
      name: Context.langData.add_modifier_groups,
    },
    {
      id: 2,
      name: Context.langData.unassign_modifier_group,
    },
    {
      id: 3,
      name: Context.langData.duplicate_items,
    },
    {
      id: 4,
      name: Context.langData.reassign_category,
    },
    {
      id: 5,
      name: Context.langData.delete,
    },
    {
      id: 6,
      name: Context.langData.Price,
    },
  ];

  const debouncedFetchItems = useCallback(
    debounce((parameters) => {
      dispatch(fetchItemsByCatId(parameters));
    }, 500),
    [dispatch]
  );

  useEffect(() => {
    if (itemsList) {
      const modifiedItems = itemsList.items?.map((item) => {
        const available = item.available ? "Active" : "Inactive";
        const inStock = item.in_stock ? "Yes" : "No";
        const price = item.price ? item.price : "0.00";
        const modifiers = item.modifiers
          .map((modifier) =>
            modifier.modifier_name
              ? `${modifier.modifier_name}${modifier.is_optional ? "*" : ""}`
              : "-"
          )
          .join(",");

        return {
          ...item,
          id: item.item_id,
          available,
          in_stock: inStock,
          price,
          modifiers,
        };
      });

      setItems(modifiedItems);
    }
  }, [itemsList]);

  useEffect(() => {
    dispatch(fetchItemTypes());
    dispatch(fetchAllCategories());
    if (categories) parseCategoryList();
  }, []);

  useEffect(() => {
    handleFetchItems(params);
  }, [params, debouncedFetchItems]);

  const handleFetchItems = () => {
    let parameters = { ...params };
    if (parameters.filterBy && parseInt(parameters.filterBy, 10) !== 0) {
      parameters = {
        ...parameters,
        filterBy: `category,${parameters.filterBy}`,
      };
    } else {
      delete parameters.filterBy;
    }
    debouncedFetchItems(parameters);
  };

  const parseCategoryList = () => {
    const categoryList = categories.map((d) => ({ ...d, id: d.cat_id }));

    setCategoryList([{ name: "All", id: 0 }].concat(categoryList));
  };

  const findItem = useMemo(() => {
    const filtered = itemTypes?.filter((e) => e.status === 1);
    const filterIds = filtered.map((i) => i.item_type_id);
    return itemtypeName.filter((obj) => filterIds.includes(obj.id));
  }, [itemTypes]);

  const assignCategoryCallback = (isSuccess, response) => {
    closingDrawer();
    if (isSuccess) {
      if (response.failed_items.length > 0) {
        let items = "";
        response.failed_items.forEach((it) => {
          items = `${items + it.name};`;
        });

        setSnackbar({
          duration: 10000,
          message:
            Context.langData.reassign_category_success +
            response.succeed_items.length +
            Context.langData.reassign_category_success_item +
            response.failed_items.length +
            Context.langData.reassign_category_failed_item +
            items,
          severity: "warn",
        });
      } else {
        toggleDrawer("openReassignCategoryCompleteDialog", true);
      }
    } else {
      setSnackbar({
        message: Context.langData.reassign_category_failed,
        severity: "error",
      });
    }
    handleFetchItems();
  };

  const assignAddonCallback = (isSuccess, response) => {
    closingDrawer();
    if (isSuccess) {
      if (response.failed_items.length > 0) {
        let items = "";
        response.failed_items.forEach((it) => {
          items = `${items + it.name};`;
        });

        setSnackbar({
          duration: 10000,
          message:
            Context.langData.reassign_category_success +
            response.succeed_items.length +
            Context.langData.reassign_category_success_item +
            response.failed_items.length +
            Context.langData.reassign_category_failed_item +
            items,
          severity: "warn",
        });
      } else {
        toggleDrawer("openAssignAddonsCompleteDialog", true);
      }
    } else {
      setSnackbar({
        message: Context.langData.reassign_category_failed,
        severity: "error",
      });
    }

    handleFetchItems();
  };

  const unAssignAddonCallback = (isSuccess, response) => {
    closingDrawer();
    if (isSuccess) {
      if (response.failed_items.length > 0) {
        let items = "";
        response.failed_items.forEach((it) => {
          items = `${items + it.name};`;
        });

        setSnackbar({
          duration: 10000,
          message:
            Context.langData.unAssign_addon_success +
            response.succeed_items.length +
            Context.langData.unAssign_addon_success_item +
            response.failed_items.length +
            Context.langData.unAssign_addon_failed_item +
            items,
          severity: "warn",
        });
      } else {
        toggleDrawer("openUnassignAddonsCompleteDialog", true);
      }
    } else {
      setSnackbar({
        message: Context.langData.unAssign_addon_failed,
        severity: "error",
      });
    }

    handleFetchItems();
  };
  const handleAssignMultiPriceChangeCallBack = (isSuccess, response) => {
    closingDrawer();
    if (isSuccess) {
      if (response.failed_items.length > 0) {
        setFailedItemsOfUpdatePrice(
          response.failed_items.map((item) => (
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
              }}>
              <Grid>
                <CircleClose color='#AB1212' />
                <AvenirBlackH4 label={item.name} />
              </Grid>
            </Grid>
          ))
        );
        toggleDrawer("openMultipleItemPriceUpdateDialog", true);
      }
      if (response.succeed_items.length > 0) {
        setSnackbar({
          duration: 10000,
          message: `${response.succeed_items.length} ${Context.langData.price_update_success_message}`,
          severity: "success",
        });
      }
    }

    handleFetchItems();
  };
  const createCallback = () => {
    setSnackbar({
      message: Context.langData.item_create_succ,
      severity: "success",
    });
    closingDrawer();
    handleFetchItems();
  };
  const editCallback = () => {
    setSnackbar({
      message: Context.langData.item_edit_succ,
      severity: "success",
    });
    closingDrawer();
    handleFetchItems();
  };

  const deleteItemCallback = (isSuccess) => {
    if (isSuccess) {
      setSnackbar({
        message: Context.langData.item_delete_succ,
        severity: "success",
      });
    } else {
      setSnackbar({
        message: Context.langData.item_delete_failed_combo,
        severity: "error",
      });
    }
    handleFetchItems();
  };

  const duplicateItemCallback = (isSuccess, response) => {
    if (isSuccess) {
      if (response.failed_items.length > 0) {
        let items = "";
        response.failed_items.forEach((it) => {
          items = `${items + it.name};`;
        });

        setSnackbar({
          duration: 10000,
          message:
            Context.langData.item_copy_succ +
            response.succeed_items.length +
            Context.langData.reassign_category_success_item +
            response.failed_items.length +
            Context.langData.reassign_category_failed_item +
            items,
          severity: "warn",
        });
      } else {
        setSnackbar({
          message: Context.langData.item_copy_succ,
          severity: "success",
        });
      }
    } else {
      setSnackbar({
        message: Context.langData.item_copy_failed,
        severity: "error",
      });
    }
    closingDrawer();
    handleFetchItems();
  };

  const handleClose = (val) => {
    if (val) {
      if (selectedId && selectedId.length > 0) {
        dispatch(
          deleteItemsByCatId(JSON.stringify(selectedId), deleteItemCallback)
        );
      }
    }
    toggleDrawer("showDialog", false);
  };

  const handleCloseDuplicate = (val) => {
    if (val) {
      const reqItems = [];

      selectedItems.map((p) => {
        const obj = {};
        obj.id = p.item_id;
        obj.name = p.name;
        reqItems.push(obj);
      });

      const request = {
        items: reqItems,
      };

      dispatch(duplicateItemsByCatId(request, duplicateItemCallback));
    }
    toggleDrawer("showDuplicateDialog", false);
  };

  const copyItemCallback = () => {
    setSnackbar({
      message: Context.langData.item_copy_succ,
      severity: "success",
    });
    closingDrawer();
    handleFetchItems();
  };
  const closingDrawer = () => {
    toggleDrawer("all", false);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const onRowClick = (row) => {
    setRowData(row);
    toggleDrawer("editOpenFlag", true);
  };
  const omMenuItemSelect = (isAl) => {
    toggleDrawer("createOpenFLag", true);
    handleCloseMenu();
    toggleDrawer("isAlcohol", isAl);
  };
  const handleChange = (id) => {
    if (id === 1) {
      omMenuItemSelect(false);
    } else if (id === 2) {
      omMenuItemSelect(true);
    } else if (id === 3) {
      toggleDrawer("isOpenComboModal", true);
      handleCloseMenu();
    } else if (id === 4) {
      toggleDrawer("isOpenPizzaModal", true);
      handleCloseMenu();
    }
  };

  const onPageChange = (pageNumber) => {
    setParams((prev) => ({ ...prev, offset: parseInt(pageNumber, 10) - 1 }));
  };

  const sortingOnClick = ({ field, order }) => {
    setParams((prev) => ({
      ...prev,
      offset: 0,
      sortBy: field,
      orderBy: order,
    }));
  };

  const onSearchText = (text) => {
    setParams({ limit: 25, offset: 0, searchText: text });
  };

  const onCategoryFilterChange = (filterVal) => {
    setParams({ ...params, filterBy: filterVal });
  };

  const onCheckedIds = (checkedIds) => {
    setSelectedId(checkedIds);
  };

  const callBack = (id) => {
    const sItems = [];
    if (id === 1) {
      // Add modifier group
      selectedId.forEach((id) => {
        const item = itemsList.items.filter((e) => e.item_id === id);
        if (item.length > 0) sItems.push(item[0]);
      });
      setSelectedItems(sItems);
      toggleDrawer("openAssignAddons", true);
    } else if (id === 2) {
      // unassign modifier group
      selectedId.forEach((id) => {
        const item = itemsList.items.filter((e) => e.item_id === id);
        if (item.length > 0) sItems.push(item[0]);
      });
      setSelectedItems(sItems);
      toggleDrawer("openUnassignAddons", true);
    } else if (id === 3) {
      // Duplicate
      selectedId.forEach((id) => {
        const item = itemsList.items.filter((e) => e.item_id === id);
        if (item.length > 0) sItems.push(item[0]);
      });
      setSelectedItems(sItems);
      if (selectedId.length > 0) {
        duplicateItems(true);
      }
    } else if (id === 4) {
      // Reassign category
      let catName = "";
      selectedId.forEach((id) => {
        const item = itemsList.items.filter((e) => e.item_id === id);
        if (item.length > 0) {
          sItems.push(item[0]);
          if (catName === "") {
            catName = item[0].category;
          } else if (catName !== item[0].category) {
            catName = Context.langData.multiple;
          }
        }
      });
      setSelectedItems(sItems);
      setCurrentCatName(catName);
      toggleDrawer("openAssignCategories", true);
    } else if (id === 5) {
      // Delete
      if (selectedId.length > 0) {
        deleteItems(true);
      }
    } else if (id === 6) {
      if (selectedId.length > 0) {
        selectedId.forEach((id) => {
          const item = itemsList.items.filter((e) => e.item_id === id);
          if (item.length > 0) sItems.push(item[0]);
        });
        setSelectedItems(sItems);
        toggleDrawer("openPriceChangeModal", true);
      }
    }
  };

  const deleteItems = (value) => {
    toggleDrawer("showDialog", value);
  };

  const duplicateItems = (value) => {
    toggleDrawer("showDuplicateDialog", value);
  };

  return (
    <Grid
      data-qaid='item_catalogue_grid'
      container
      direction='row'
      style={{ padding: 30 }}>
      <Grid
        style={{
          height: "20%",
          marginBottom: 28,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingLeft: "21px",
        }}>
        <H5
          label={Context.langData.menu}
          style={{
            color: "#007191",
            fontWeight: "500",
            fontSize: "10px",
          }}
        />
        <H6
          label={Context.langData.item_catalogue}
          style={{
            color: "#051D33",
            fontWeight: "800",
          }}
        />
      </Grid>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          padding: "0px 10px 10px 21px",
        }}>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            width: "30%",
            justifyContent: "flex-start",
          }}>
          <TopSearchBar
            value={params.searchText}
            onChange={(e) => onSearchText(validateAlphaNumeric(e.target.value))}
            placeholder={Context.langData.search_item_name}
            style={{ height: 50 }}
            width='100%'
          />
        </Grid>

        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "70%",
            marginBottom: 22,
            marginLeft: 20,
          }}>
          <Grid
            item
            data-qaid='item_categorySingleSelectView'
            style={{ width: "30%" }}>
            <AvenirBlackH4
              data-qaid='item_categorySingleSelectLabel'
              label={Context.langData.filter_by}
            />
            <SingleSelect
              list={categoryList}
              value={params.filterBy || 0}
              valueKey='id'
              displayKey='name'
              border={false}
              handleChange={(e) => {
                onCategoryFilterChange(e.target.value);
              }}
              height={50}
            />
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}>
            <ButtonMenu
              style={{ marginLeft: 10 }}
              menuItems={ButtonMenuData}
              label={Context.langData.edit}
              callBack={callBack}
              btnDisable={selectedId?.length <= 0}
            />
            <Button
              className={style.buttonClass}
              data-qaid='categories_creategrid'
              style={{
                backgroundColor: "#FFB600",
                color: "white",
                marginRight: 10,
              }}
              variant='contained'
              id='cancel'
              onClick={(event) => setAnchorEl(event.currentTarget)}>
              <PluseIcon
                data-qaid='categories_createpluse'
                style={{ marginRight: 5 }}
              />
              <AvenirBlackH4
                data-qaid='categories_createlabel'
                label={Context.langData.Create_new}
              />
            </Button>
          </Grid>
        </Grid>

        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl) && findItem?.length > 0}
          onClose={handleCloseMenu}
          style={{ zIndex: 999 }}>
          {findItem.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              onClick={() => handleChange(item.id)}>
              {item.value}
              <span width='20'>&nbsp;&nbsp;</span>
              {item.isBeta && (
                <BetaIcon color='#051d33' width={36} height={18} />
              )}
            </MenuItem>
          ))}
        </Menu>
      </Grid>
      <NewDataTable
        data-qaid='item_catalogue_table'
        offset={params.offset}
        total={Math.ceil(itemsList.total / 25)}
        columns={columns}
        rows={items}
        onCheckedIds={onCheckedIds}
        isOnClick
        onClickAction={onRowClick}
        sortingOnClick={sortingOnClick}
        onPageChange={onPageChange}
      />
      {drawers.createOpenFLag && (
        <CreateItem
          open={drawers.createOpenFLag}
          itemsList={items}
          isAlcohol={drawers.isAlcohol}
          callback={createCallback}
          onClose={() => {
            toggleDrawer("createOpenFLag", false);
          }}
          deleteItemCallback={deleteItemCallback}
        />
      )}
      {drawers.editOpenFlag && (
        <CreateItem
          open={drawers.editOpenFlag}
          id={rowData.cat_id}
          itemsList={items}
          itemId={rowData.id}
          callback={editCallback}
          onClose={() => {
            toggleDrawer("editOpenFlag", false);
          }}
          deleteItemCallback={deleteItemCallback}
          copyItemCallback={copyItemCallback}
        />
      )}
      {drawers.isOpenComboModal && (
        <CombosModal
          itemId={rowData.id}
          open={drawers.isOpenComboModal}
          callback={createCallback}
          editCallback={editCallback}
          onClose={() => closingDrawer()}
          deleteItemCallback={deleteItemCallback}
          copyItemCallback={copyItemCallback}
        />
      )}
      {drawers.isOpenPizzaModal && (
        <PizzasModal
          itemId={rowData.id}
          open={drawers.isOpenPizzaModal}
          callback={createCallback}
          editCallback={editCallback}
          onClose={() => {
            closingDrawer();
          }}
          deleteItemCallback={deleteItemCallback}
          copyItemCallback={copyItemCallback}
        />
      )}
      {drawers.openAssignCategories && (
        <ItemAssignCategories
          data-qaid='printerlist_assignprinter'
          open={drawers.openAssignCategories}
          items={selectedItems}
          currentCategory={currentCatName}
          onClose={() => toggleDrawer("openAssignCategories", false)}
          callback={assignCategoryCallback}
        />
      )}
      {drawers.openAssignAddons && (
        <ItemAssignAddons
          data-qaid='printerlist_assignAddon'
          open={drawers.openAssignAddons}
          items={selectedItems}
          onClose={() => toggleDrawer("openAssignAddons", false)}
          callback={assignAddonCallback}
        />
      )}
      {drawers.openUnassignAddons && (
        <ItemUnassignAddons
          data-qaid='printerlist_assignAddon'
          open={drawers.openUnassignAddons}
          items={selectedItems}
          onClose={() => toggleDrawer("openUnassignAddons", false)}
          callback={unAssignAddonCallback}
        />
      )}
      {drawers.openPriceChangeModal && (
        <AssignPriceToItems
          data-qaid='assign-multiple-item-price-change'
          open={drawers.openPriceChangeModal}
          items={selectedItems}
          onClose={() => toggleDrawer("openPriceChangeModal", false)}
          callback={handleAssignMultiPriceChangeCallBack}
        />
      )}
      {snackbar && (
        <SnackBar
          data-qaid='categories_snackbar'
          open
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      )}
      <Dialog
        showDialog={drawers.showDialog}
        body={Context.langData.bulk_delete_items_message_main}
        heading={
          selectedId.length > 1
            ? Context.langData.delete_item_multiple.replace(
                // eslint-disable-next-line no-template-curly-in-string
                "${itemNum}",
                selectedId.length
              )
            : Context.langData.delete_item_single
        }
        IsError={false}
        handleClose={handleClose}
        showExtra
        extraBody={
          selectedId.length > 1
            ? Context.langData.delete_item_message_multiple.replace(
                // eslint-disable-next-line no-template-curly-in-string
                "${itemNum}",
                selectedId.length
              )
            : Context.langData.delete_item_message_single
        }
      />
      <AlertDialogDelete
        showDialog={drawers.showDuplicateDialog}
        heading={
          selectedItems.length > 1
            ? Context.langData.duplicate_item_multiple.replace(
                // eslint-disable-next-line no-template-curly-in-string
                "${itemNum}",
                selectedItems.length
              )
            : Context.langData.duplicate_item_single
        }
        body={Context.langData.bulk_duplicate_items_message_main}
        isDeleteError={false}
        handleClose={handleCloseDuplicate}
        showExtra
        extraBody={
          selectedId.length > 1
            ? Context.langData.duplicate_item_message_multiple.replace(
                // eslint-disable-next-line no-template-curly-in-string
                "${itemNum}",
                selectedId.length
              )
            : Context.langData.duplicate_item_message_single
        }
        buttonLabel={Context.langData.duplicate}
      />
      <AlertDialog
        open={drawers.openAssignAddonsCompleteDialog}
        onClose={() => {
          toggleDrawer("openAssignAddonsCompleteDialog", false);
        }}
        title={
          selectedItems.length > 1
            ? Context.langData.assign_addon_msg_multiple.replace(
                // eslint-disable-next-line no-template-curly-in-string
                "${itemNum}",
                selectedItems.length
              )
            : Context.langData.assign_addon_msg_single
        }
        primaryButtonText={Context.langData.done}
        onPrimaryAction={() =>
          toggleDrawer("openAssignAddonsCompleteDialog", false)
        }
        // eslint-disable-next-line react/no-children-prop
        bodyText={Context.langData.assign_addon_complete_desc}
        primaryButtonType='single'
      />
      <AlertDialog
        open={drawers.openUnassignAddonsCompleteDialog}
        onClose={() => {
          toggleDrawer("openUnassignAddonsCompleteDialog", false);
        }}
        title={
          selectedItems.length > 1
            ? Context.langData.unAssign_addon_complete_title_multiple.replace(
                // eslint-disable-next-line no-template-curly-in-string
                "${itemNum}",
                selectedItems.length
              )
            : Context.langData.unAssign_addon_complete_title_single
        }
        primaryButtonText={Context.langData.done}
        onPrimaryAction={() =>
          toggleDrawer("openUnassignAddonsCompleteDialog", false)
        }
        // eslint-disable-next-line react/no-children-prop
        bodyText={Context.langData.unAssign_addon_complete_desc}
        primaryButtonType='single'
      />
      <AlertDialog
        open={drawers.openReassignCategoryCompleteDialog}
        onClose={() => {
          toggleDrawer("openReassignCategoryCompleteDialog", false);
        }}
        title={
          selectedItems.length > 1
            ? Context.langData.reassign_category_multiple.replace(
                // eslint-disable-next-line no-template-curly-in-string
                "${itemNum}",
                selectedItems.length
              )
            : Context.langData.reassign_category_single
        }
        primaryButtonText={Context.langData.done}
        onPrimaryAction={() =>
          toggleDrawer("openReassignCategoryCompleteDialog", false)
        }
        // eslint-disable-next-line react/no-children-prop
        bodyText={Context.langData.reassign_category_confirmation}
        primaryButtonType='single'
      />
      <AlertDialog
        open={drawers.openMultipleItemPriceUpdateDialog}
        onClose={() => {
          toggleDrawer("openMultipleItemPriceUpdateDialog", false);
        }}
        title={Context.langData.failed_to_update_prices_for}
        primaryButtonText={Context.langData.done}
        onPrimaryAction={() =>
          toggleDrawer("openMultipleItemPriceUpdateDialog", false)
        }
        // eslint-disable-next-line react/no-children-prop
        children={failedItemsOfUpdatePrice}
        primaryButtonType='single'
      />
    </Grid>
  );
};

export default ItemCatalogue;
